#login-hero {
    background: #0248ff url(./login_bg.a59e41071698.jpg);    
    background-size: cover;
    inset: 0;
    position: fixed;
    z-index: -1;
}

#circular-progress-btn-login {
    margin-top: -12px;
    margin-left: -12px;
}

#login-container::before,
#login-container::after {
    display: block;
    clear: both;
    content: " "; /* Alt + 0160 */
}