#table-transactions {
    min-width: 650px;
}

#collapse-details {
    margin: auto;
    width: 100%;
}

@media (min-width: 992px) {
    #collapse-details {
        width: 70% !important;
    }
}