#step-content {
    width: 100%;
}

.circular-progress-action-btn {
    margin-top: -12px;
    margin-left: -12px;
}

@media (max-width: 575.98px) {
    #action-buttons {
        display: block !important;
    }

    #action-btn-back,
    #action-btn-back button,
    #action-btn-skip,
    #action-btn-skip button,
    #action-btn-next,
    #action-btn-next button,
    #action-btn-add-bets,
    #action-btn-add-bets button,
    #action-btn-summary,
    #action-btn-summary button,
    #action-btn-download,
    #action-btn-download button {
        width: 100%;
    }

    #action-btn-back,
    #action-btn-skip,
    #action-btn-add-bets {
        margin-bottom: .5rem;
    }

    #action-btn-summary {
        margin-top: .5rem;
    }
}

@media (min-width: 576px) {
    #step-content {
        width: 70%;
    }
}