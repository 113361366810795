body {
    background-color: #f8f9fa !important;
}

.MuiCircularProgress-root {
    top: 50%;
    left: 50%;
}

#circular-progress-onload {
    margin-top: -24px;
    margin-left: -24px;
}