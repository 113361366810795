html, body, #root {
    height: 100%;
}

.d-grid {
    display: grid !important;
}

.justify-self-center {
    justify-self: center !important;
}

.w-inherit {
    width: inherit !important;
}