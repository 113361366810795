#form-control-schedule {
    width: 100%;
}

@media (min-width: 576px) {
    #form-control-schedule {
        width: 223px !important;
    }
}

