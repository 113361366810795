.deductions-form-control {
    width: 100%;
}

#dialog-calculated-bets {
    background-color: #f8f9fa !important;
}

#container-calculated-bets {
    width: 100%;
}

@media (max-width: 767.98px) {
    #table-deductions .MuiTableCell-root {
        text-align: center !important;
    }
}

@media (min-width: 768px) {
    .deductions-form-control {
        width: 223px !important;
    }
}

@media (min-width: 576px) {
    #container-calculated-bets {
        width: 70%;
    }
}