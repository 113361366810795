#register-hero {
    background: #0248ff url(./register_bg.c6341fccd891.png);
    background-size: cover;
    inset: 0;
    position: fixed;
    z-index: -1;
}

#register-container::before,
#register-container::after {
    display: block;
    clear: both;
    content: " "; /* Alt + 0160 */
}