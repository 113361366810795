#table-users {
    min-width: 560px;
}

#fab-add-user {
    right: 25px;
    bottom: 25px;
}

.circular-progress-action-btn {
    margin-top: -12px;
    margin-left: -12px;
}

@media (max-width: 575.98px) {
    #row-status .MuiFormControlLabel-root {
        max-width: 100%;
        flex: 100%;
    }
}

@media (max-width: 767.98px) {
    #paper-user-details {
        width: 100% !important;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    #paper-user-details {
        width: 80% !important;
    }
}