#appbar-profile-name {
    white-space: nowrap;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis; 
}

@media (min-width: 576px) {
    #appbar-profile-name {
        white-space: nowrap;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis; 
    }
}